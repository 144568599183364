/**
 * Normalize.css and sTailwind's base styles.
 */
/* commented as not necessary in latest update */
/* @import-normalize; */

/* @import 'tailwindcss/base'; */

/**
  * Custom base styles, applied after the tailwind-base classes
  */

html {
    font-size: 62.5%;
    font-family: Poppins, Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #121212;
}

body {
    font-size: 13px;
    line-height: 1.4;
    overflow-x: hidden;
}

html,
body,
#root {
    position: relative;
    margin: 0;
    min-height: 100vh;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
    transition: none !important;
    animation: none !important;
}

button:focus {
    outline: none;
}

@keyframes autofill {

    0%,
    100% {
        background: transparent;
    }
}

input:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill !important;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

:focus {
    outline-color: transparent;
}

.loginInnerDiv {
    border: 1px solid;
    border-radius: 7px;
    margin: 9rem;
    opacity: 8;
    position: relative;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    padding: 6rem;

}

.marker {
    color: white;
    width: auto;
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgb(100 180 237 / 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #62B3ED;
    white-space: nowrap;
    padding: 6px;
}

.marker-cluster {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(100 180 237 / 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 2px solid #62B3ED;
    cursor: pointer;
}

.mapboxgl-map {
    border-radius: 10px;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib a {
    background: inherit !important;
}

.indeterminateIcon {
    color: #ff9800;
}

.grid-open-icon img {
    width: 15px;
    height: 40px;
    max-width: max-content;
}

.details-remove-icon svg {
    margin-top: 8px;
    cursor: pointer;
}

.grid-open-icon {
    cursor: pointer;
}

.assign-test-codes-tabs {
    border-radius: 20px 0px 0px 0px;
}

.assign-test-codes-tabs button:hover {
    background-color: #e6e6e6;
}

.ag-theme-material.blue-header .ag-header {
    background: #f5fcff;
    border: 1px solid #e6e6e6;
}

.ag-theme-material.select-locations .ag-header {
    background: #f5fcff;
    border: 1px solid #e6e6e6;
}

.ag-theme-material .ag-checkbox-input-wrapper::after {
    color: #4eaeec !important;
}


.ag-theme-material.details-grid .ag-header {
    background: #f5fcff;
    border: 1px solid #e6e6e6;
}

.open-task-icon i {
    color: #4eaeec;
    font-size: 20px;
    line-height: unset;
}

.ag-status-column {
    display: flex;
    align-items: center;
}

.ag-status-column svg {
    margin-right: 8px;
}

.ag-status-column.Not.Started svg g {
    fill: #b2b2b2;
}

.ag-status-column.In.Progress svg g {
    fill: #7fbce5;
}

.ag-status-column.Canceled svg g {
    fill: #da0a00;
}

.ag-status-column.Completed svg g {
    fill: #339933;
}

.ag-test-codes-edit-column {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.ag-test-codes-edit-column svg {
    margin-left: 8px;
}


.ag-test-codes-edit-column {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.ag-test-codes-edit-column svg {
    margin-left: 8px;
}


.ag-dropdown-edit-column {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.ag-dropdown-edit-column svg {
    margin-left: 8px;
}

.logistics-header-odd {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    padding-bottom: 1rem;
    background-color: #DDE6ED !important;
}
.logistics-header-even {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    padding-bottom: 1rem;
}


@media only screen and (min-width: 767px) and (max-device-width: 990px) {

    .scheduleWrapper {
        height: 200px;
    }

}